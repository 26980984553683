<template>
  <v-container>
    <v-card class="pa-4">
      <h2 class="mt-5">IVA REPORT TABLE</h2>
      <v-simple-table class="mt-4">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">
                Description
              </th>
              <th class="text-left">

              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Total No of CDPs</td>
              <td>{{ ivas.totalCDPS }}</td>
            </tr>
            <tr>
              <td>No of CDP Approved</td>
              <td>{{ ivas.totalCDP }}</td>
            </tr>
            <tr>
              <td>No of CDP Completed</td>
              <td>{{ ivas.completedCdps }}</td>
            </tr>
            <tr>
              <td>No of CDP Ongoing</td>
              <td>{{ ivas.ongoingCdps }}</td>
            </tr>
            <tr>
              <td>No of CDP Cancelled</td>
              <td>{{ ivas.cancelledCdps }}</td>
            </tr>
            <tr>
              <td>No of CDP Not Yet Funded</td>
              <td>{{ ivas.cdpsNotfunded }}</td>
            </tr>
            <tr>
              <td>Total Amount of CDP </td>
              <td>{{ }}</td>
            </tr>
            <tr>
              <td>No of Profile of Beneficiaries of CDPs</td>
              <td>{{ ivas.cdpProfile }}</td>
            </tr>
            <tr>
              <td>No of GrDP Approved</td>
              <td>{{ ivas.totalGrDP }}</td>
            </tr>
            <tr>
              <td>No of GrDP Completed</td>
              <td>{{ ivas.completedGrDP }}</td>
            </tr>
            <tr>
              <td>No of GrDP Ongoing</td>
              <td>{{ ivas.ongoingGrDP }}</td>
            </tr>
            <tr>
              <td>No of GrDP Cancelled</td>
              <td>{{ ivas.cancelledGrDP }}</td>
            </tr>
            <tr>
              <td>No of GrDP Not Yet Funded</td>
              <td>{{ ivas.grdpNotfunded }}</td>
            </tr>
             <tr>
              <td>Total Amount of GrDP</td>
              <td>{{  }}</td>
            </tr>
            <tr>
              <td>No of Profile of Beneficiaries of GrDPs</td>
              <td>{{ ivas.grdpProfile }}</td>
            </tr>
            <tr>
              <td> No of Environmental Screening Conducted</td>
              <td>{{ ivas.envScreen }}</td>
            </tr>
            <tr>
              <td>No of Social Screening Conducted</td>
              <td>{{ ivas.socialScreen }}</td>
            </tr>
            <tr>
              <td>No of VLD Protocol Developed</td>
              <td>{{ ivas.vld }}</td>
            </tr>
            <tr>
              <td>No of Environmental Audit Conducted</td>
              <td>{{ ivas.envAudit }}</td>
            </tr>
            <tr>
              <td>No of Social Audit Conducted</td>
              <td>{{ ivas.socialAudit }}</td>
            </tr>
            <tr>
              <td>No of Mps Approved</td>
              <td>{{ ivas.totalMPS }}</td>
            </tr>
            <tr>
              <td>No of Mps Approved Under Health Sector</td>
              <td>{{ ivas.mpsHealth }}</td>
            </tr>
            <tr>
              <td>No of Mps Approved Under Education Sector</td>
              <td>{{ ivas.mpsEducation }}</td>
            </tr>
            <tr>
              <td>No of Mps Approved Under Water and Sanitation sector</td>
              <td>{{ ivas.mpsWater }}</td>
            </tr>
            <tr>
              <td>No of Mps Approved Under Nutrition Sectors</td>
              <td>{{ ivas.mpsNutrition }}</td>
            </tr>
            <tr>
              <td>No of Mps Completed</td>
              <td>{{ ivas.completedMps }}</td>
            </tr>
            <tr>
              <td>No of Mps Ongoing</td>
              <td>{{ ivas.ongoingMps }}</td>
            </tr>
            <tr>
              <td>No of Mps Cancled</td>
              <td>{{ ivas.cancelledMps }}</td>
            </tr>
            <tr>
              <td>No of Mps Not Yet Funded</td>
              <td>{{ ivas.mpsNotfunded }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>

    <vue-excel-xlsx class="mt-2 text-center" :data="[ivas]" :columns="ivaHeaders" :filename="'IVAs Data'"
      :sheetname="'IVAs Sheet'">
      <v-btn small rounded class="white--text" color="primary">
        Export to Excel</v-btn>
    </vue-excel-xlsx>
  </v-container>
</template>

<script>
import { apiClient } from "@/services";
export default {
  data() {
    return {
      mps: [],
      cdps: [],
      beneficiaries: [],
      ivaHeaders: [
        { label: "Total No of CDPs", field: "totalCDPS" },

        { label: "No of CDP Approved", field: "totalCDP" },
        { label: "No of CDP Completed", field: "completedCdps" },

        { label: "No of CDP Ongoing", field: "ongoingCdps" },

        { label: "No of CDP Cancelled", field: "cancelledCdps" },

        { label: "No of CDP Not Yet Funded", field: "cdpsNotfunded" },
        { label: "Total Amount of CDPs", field: "" },
        { label: "No of Profile of Beneficiaries of CDPs", field: "envScreen" },
        { label: "No of GrDP Approved", field: "totalGrDP" },

        { label: "No of Profile of Beneficiaries of GrDPs", field: "socialScreen" },
        { label: "No of GrDP Completed", field: "completedGrDP" },

        { label: "No of GrDP Ongoing", field: "ongoingGrDP " },

        { label: "No of GrDP Cancelled", field: "cancelledGrDP" },

        { label: "No of GrDP Not Yet Funded", field: "grdpNotfunded" },
        { label: "Total Amount of GrDP ", field: "" },
        { label: "No of Environmental Screening Conducted", field: "cdpProfile" },
        { label: "No of Social Screening Conducted", field: "grdpProfile" },
        { label: "No of VLD Protocol Developed", field: "vld" },
        { label: "No of Environmental Audit Conducted", field: "envAudit" },
        { label: "No of Social Audit Conducted", field: "socialAudit" },
        { label: "No of Mps Approved", field: "totalMPS" },
        { label: "No of Mps Approved Under Health Sector", field: "mpsHealth" },
        { label: "No of Mps Approved Under Education Sector", field: "mpsEducation" },
        { label: "No of Mps Approved Under Water and Sanitation sector", field: "mpsWater" },
        { label: "No of Mps Approved Under Nutrition Sectors", field: "mpsNutrition" },
        { label: "No of Mps Completed", field: "completedMps" }, { label: "No of Mps Ongoing", field: "ongoingMps" }, { label: "No of Mps Cancled", field: "cancelledMps" }, { label: "No of Mps Not Yet Funded", field: "mpsNotfunded" }

      ]
    }
  },
  computed: {
    CDP() {
      return this.cdps.filter(cdp => cdp.projects == 'CDP')
    },

    GrDP() {
      return this.cdps.filter(grdp => grdp.projects == 'GrDP')
    },

    CDPBeneficiaries() {
      return this.beneficiaries.filter(beneficiary => beneficiary.projects == 'CDP')
    },

    GrDPBeneficiaries() {
      return this.beneficiaries.filter(beneficiary => beneficiary.projects == 'GrDP')
    },


    ivas() {
      return {
        totalMPS: this.mps.length,
        completedMps: this.mps.filter(mps => mps.Status == 'Completed').length,
        ongoingMps: this.mps.filter(mps => mps.Status == 'Ongoing').length,
        cancelledMps: this.mps.filter(mps => mps.Status == 'Cancelled').length,
        mpsNotfunded: this.mps.filter(mps => mps.Status == 'Not yet Funded').length,
        mpsHealth: this.mps.filter(mps => mps.Sector == 'Health').length,
        mpsEducation: this.mps.filter(mps => mps.Sector == 'Education').length,
        mpsWater: this.mps.filter(mps => mps.Sector == 'Water and Sanitation').length,
        mpsNutrition: this.mps.filter(mps => mps.Sector == 'Nutrition').length,
        totalCDPS: this.cdps.length,
        envScreen: this.cdps.filter(cdp => cdp.EnvironmentScreen == 'Yes').length,
        socialScreen: this.cdps.filter(cdp => cdp.SocialScreen == 'Yes').length,
        cdpProfile: this.CDPBeneficiaries.reduce((sum, val) => sum + val.noOfBeneficiaries, 0),
        grdpProfile: this.GrDPBeneficiaries.reduce((sum, val) => sum + val.noOfBeneficiaries, 0),
        vld: this.cdps.reduce((sum, cdp) => sum + cdp.VLDProtocol, 0),
        envAudit: this.cdps.filter(cdp => cdp.EnvironmentAudit == 'Yes').length,
        socialAudit: this.cdps.filter(cdp => cdp.SocialAudit == 'Yes').length,

        // all cdp info
        totalCDP: this.CDP.length,
        completedCdps: this.CDP.filter(cdp => cdp.CDPStatus == 'Completed').length,
        ongoingCdps: this.CDP.filter(cdp => cdp.CDPStatus == 'Ongoing').length,
        cancelledCdps: this.CDP.filter(cdp => cdp.CDPStatus == 'Cancelled').length,
        cdpsNotfunded: this.CDP.filter(cdp => cdp.CDPStatus == 'Not yet Funded').length,
        // end of cdp info 

        // all GrDP info
        totalGrDP: this.GrDP.length,
        completedGrDP: this.GrDP.filter(grdp => grdp.CDPStatus == 'Completed').length,
        ongoingGrDP: this.GrDP.filter(grdp => grdp.CDPStatus == 'Ongoing').length,
        cancelledGrDP: this.GrDP.filter(grdp => grdp.CDPStatus == 'Cancelled').length,
        grdpNotfunded: this.GrDP.filter(grdp => grdp.CDPStatus == 'Not yet Funded').length,
        // end of GrDP info 
      }
    }
  },

  created() {

    apiClient
      .get("/cdps_info?u=2")
      .then((res) => {

        if (Array.isArray(res.data)) {
          this.cdps = res.data;
        } else {
          this.displayMsg(res.data, "error");
        }
      })
      .catch((err) => this.displayMsg(err.message, "error"));

    this.getmps()
    this.getBeneficiaries()

  },

  methods: {
    async getmps() {
      apiClient
        .get("/mps_info?u=2")
        .then((res) => {

          if (Array.isArray(res.data)) {
            this.mps = res.data;
          } else {
            this.displayMsg(res.data, "error");
          }
        })
        .catch((err) => this.displayMsg(err.message, "error"));
    },
    async getBeneficiaries() {
      apiClient
        .get("/community_info?u=3")
        .then((res) => {

          if (Array.isArray(res.data)) {
            this.beneficiaries = res.data;

          } else {
            this.displayMsg(res.data, "error");
          }
        })
        .catch((err) => this.displayMsg(err.message, "error"));

    }

  },


}
</script>